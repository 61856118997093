import { Component } from "react";
import axios from 'axios'
import Dua from './Dua.js'
import Loader from '../../loader.gif'

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
    // baseURL: "http://localhost:5000"                        //change     
})

var prayerData = {dua_data:[], data:[]};

export default class CompletedDuas extends Component{
    constructor(props){
        super(props);        
        this.state = {
            isLoaded: false
        }
        this.getData = this.getData.bind(this);
        this.getDua = this.getDua.bind(this);
    }

    getData(){
        // the dataLoaded is used to re-render prayer component after JSON data is loaded and ready to display
        if(this.state.isLoaded === false){
            api
            .get('/')
            .then((res) => {prayerData = res.data; this.setState({isLoaded:true})})
            .catch(err => {
            console.log(err);
            });
        }
      
    }

    getDua(props){
        const dua = prayerData.dua_data;
        for(var i=0; i<dua.length; i++){
            if(dua[i].prayerName === props.prayerName)
                {return dua[i]; }
        }  
    }

       
    render(){
          
        this.getData();

        //2. display all the prayers to the screen

        const allPrayers = (prayerData.data.length > 0) ? prayerData.data.map((datas, index) => {                       
            
                return(
                    
                    <div key = {index}>
                        { (!datas.active) ? <Dua prayer={datas} join={false} dua={this.getDua(datas)}/> : null}
                                        
                    </div>
                );  
            }) : <h5>There are no completed duas in the system.</h5>
       
        
        return(           
            <div className="Prayer">
                
                <h2>Completed Duas</h2>                
                {(this.state.isLoaded) ? allPrayers : <img src={Loader} alt="loading..." className="loader"/> }
                
            </div>             
        );
    }
}