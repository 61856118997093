import { Component } from "react"
import axios from "axios"
import Dua from './Prayer/Dua.js'
import Loader from '../loader.gif'


const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
    // baseURL: "http://localhost:5000"                        //change     
})
var prayerData = {dua_data:[], data:[]};
var dispPrivatePrayer = null;

export default class Private extends Component{
    constructor(props){
        super(props);
        this.state = {
            code: props.code,
            dataLoaded: false,
            
        }
        this.getData = this.getData.bind(this);
        this.getDua = this.getDua.bind(this);
    }

    getData(){
        
        // the dataLoaded is used to re-render prayer component after JSON data is loaded and ready to display
        if(this.state.dataLoaded === false){
            api
            .get('/private?code='+this.state.code)
            .then((res) => {if(res.data.data.length>0) {prayerData = res.data; this.setState({dataLoaded: true});} })
            .catch(err => {
            console.error(err);
            });            
        }
        else return (<p>Error loading dua.</p>)
    }

    getDua(props){
        for(var i=0; i<prayerData.dua_data.length; i++){
            if(prayerData.dua_data[i].prayerName === props.prayerName)
                {return prayerData.dua_data[i]; }
        }  
    }
    componentWillUnmount(props){
        prayerData = {dua_data:[], data:[]};
    }

    render(){
        this.getData();

        
        dispPrivatePrayer = (prayerData.data.length > 0) ? prayerData.data.map((datas, index) => {     
            if(datas.prayerId === this.state.code){                
                return(                
                    <div key={index}>
                        {(datas.active) ?<Dua prayer={datas} join={true} dua={this.getDua(datas)} />: <h5>JazakAllah! This dua is complete</h5>}
                                        
                    </div>
                ); 
            }
            else return null;  
        })  : <img src={Loader} alt="loading..." className="loader"/>;


        return(
            <>
                <div className="Prayer">
                    {/* {(prayerData.data.length>0) ? console.log(prayerData.data[0].prayerFor) : console.log("empty")} 
                    {console.log(this.state.code)} */}
                    {this.state.dataLoaded && dispPrivatePrayer} 
                
                </div>
            </>
        );
    }
}