import { Component } from "react";
// import JoinPrayer from "./JoinPrayer";
import "../../App.css"
// import Dua from "./Dua.js"


export default class DuaSnapshot extends Component{
    constructor(props){
        super(props);
        this.state = {
            prayer: props.prayer,
            dua: props.dua,
            joinPrayer: props.join,
            showPrayer: false,
            buttonText: "Add to Count",
            fontColor:"#fefefa",
            bgColor:"#339999",
            borderColor: "1px solid #B5EAD7"
        }
        this.handleOnButtonClick = this.handleOnButtonClick.bind(this);
    }
    handleOnButtonClick(e){
        e.preventDefault();
        this.setState({showPrayer: true});
        this.props.showPrayerDetails(this.state.prayer, this.state.dua);
    }
    
    componentWillUnmount(){
        this.setState({
            prayer:[]
        })
    }
    render(){
    return(
        <>
        {/* {(this.state.showPrayer) ? <div><Dua prayer={this.state.prayer} join={false} dua={this.state.dua} /></div> : */}
        
            <div className="DuaSnapshot">
                <h4>Dua For: <span className="prayerfor">{this.state.prayer.prayerFor}</span></h4><br/>
                <p>Dua Name: <span id="prayername">{this.state.prayer.prayerName}</span></p><br/>            
                <p>Amount to be Recited: {this.state.prayer.totalCount}</p>
                <p>Total Completed Count: {this.state.prayer.tally}</p>
                <br/>
                <button onClick={this.handleOnButtonClick}><p><strong>View Details</strong></p></button>                        
                <br/>
            </div>                        
        
        </>
    );
}
}