import {Component} from "react"
import axios from 'axios'
import ReactGA from 'react-ga';



var messagesList = [];
const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
       
})

class messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prayer: props.prayer,
            messageText: '',
            messageFrom: props.name,
            messageSend: false,
            messagesLoaded: false
        }
        this.recordMessage = this.recordMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }
    loadMessages(){
        var data = this.state.prayer;
        if (data.hasOwnProperty("comments") && data.comments.length > 0) {messagesList = data.comments};
        ReactGA.event({
            category: 'Messages',
            action: 'User viewed comment messages'
            });
    }
    async reloadMessages(){
        var data = [];
        var loaded = this.state.messagesLoaded;
        await api.get('messages', {params: {code: this.state.prayer.prayerId}})
            .then((resp) => {
                if(resp.data !== ''){
                data = resp.data; 
                if(data.comments.length > messagesList.length) {this.state.prayer = data};
                this.setState({messagesLoaded: !loaded});}
            })
            .catch((err) => {console.log(err)})
        
    }
    recordMessage(e){
        e.preventDefault();
        var messageTexts = e.target.value;
        this.setState({messageText: messageTexts}); 
    }
    sendMessage(e) {
        e.preventDefault();
        var updateMessageComposition = {from: this.state.messageFrom, text: ''};
        updateMessageComposition.text = this.state.messageText;
        messagesList.push(updateMessageComposition);
        var data = {messages: messagesList, prayer: this.state.prayer}
        api.post('updatecomments', data)
            .then((resp)=>{
                var dataupdated = resp.data; 
                messagesList = dataupdated.comments;
                this.setState({messageSend: true});
            })
            .catch(error => {console.log(error)})
        ReactGA.event({
            category: 'Messages',
            action: 'User made a comment'
            });
        setTimeout(() => { this.reset() }, 500)
    }
    refreshMessages(e){
        e.preventDefault();
        this.reloadMessages();
        // this.setState({messagesLoaded: !this.state.messagesLoaded});
        setTimeout(() => { this.reset() }, 500)
    }

    reset(){
        this.setState({messageSend: false, messageText: '', messagesLoaded: false})
    }

    componentWillUnmount(){
        messagesList = [];
    }
    render(){
        this.loadMessages();
        var allMessages = (messagesList.length > 0) ? messagesList.map((msg,index) => {
            return(
                <h5 key={index}><em>{msg.from}</em>&nbsp;<strong>&gt;</strong>&nbsp;{msg.text}</h5>
            )
        }) : null;
    return (
        <div className='comment-messages'>
            <form >
                <h5>Messages</h5>
                <div><button onClick={(e)=>this.refreshMessages(e)}>Check for new messages</button></div>
                <div className='display'>{allMessages}</div>
                <textarea maxLength={300} placeholder='Write something...' onChange={this.recordMessage.bind(this)} value={this.state.messageText} />
                <button onClick={(e)=>this.sendMessage(e)}>Send</button>
            </form>
        </div>
    )}
}

export default messages; 
