import { Component } from "react"
import Login from "./Prayer/Login";

export default class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            showLogin: false,
            showLogOut: false,
            buttonLogin: "Sign In to Your Account",
            user: {}
        }
        this.handleLogin = this.handleLogin.bind(this);
        this.showUserInSession = this.showUserInSession.bind(this);
    }
    handleLogin(){
        var showLogin = !this.state.showLogin;
        var buttonLogin = this.state.buttonLogin;
        
        if(showLogin === true) buttonLogin = "Cancel SignIn"
        else buttonLogin = "Sign In to Your Account"

        this.setState({showLogin: showLogin, buttonLogin:buttonLogin });
        
    }
    toggleLogOutButton(status){
        this.setState({showLogOut:status});
    }
    showUserInSession(){
        // var username = localStorage.getItem("userinsession").toString();
        var username = sessionStorage.getItem("userinsession");
        if(username !== null) username = username.toString();
        if(username !== '') {
            return(<><h5>{username}</h5></>);            
        }
        else return (<></>)
    }
    render() {
        
        return (
            <>
            {/* <div>
                <div className="user-info"><span>{this.showUserInSession()}</span></div>
            </div> */}
            <div className="banner"><p>Dear reciters, Our website has recently gone through a migration. For private recitations you were given a url: https://creativewebprojects.tech/private/*your-private-code*. All private duas will now be using: http://duarequest.org/private/*your-private-code*. We apologise for the inconvenience.</p></div>
            <div className="Home">
                <h4>Salaamun alaikum! Welcome to duarequests.org ,</h4>
                <h4>a site created to assist those with specific hajaat (requests) in a collective form. </h4><br />
                <h5>The power of prayer has been noted in many Hadith. Collective prayer is recognized as a highly effective method for fulfillment of hajaat. This site has been created to help keep track of different amaal so we can InshaAllah help each other attain completion.
                    May Allah SWT accept your Duas and grant all your hajaat. Ameen.</h5>
                    
            </div>
            {/* <button onClick={this.handleLogin} >{this.state.buttonLogin}</button>
            {(this.state.showLogin) &&
            <Login handleLogin={this.handleLogin}/>} */}

                
                
            </>
        );
    }
}


