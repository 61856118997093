import { Component} from "react";
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
       
})

var tracker = "";
export default class CreateAccount extends Component{
    constructor(props){
        super(props);
        this.state = {
            user:{
            fname: '',
            lname: '',
            email: '',
            password: ''},
            registeredUser: {},
            registered: false,
            error: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.confirmation = this.confirmation.bind(this);
        this.cancelRegistration = this.cancelRegistration.bind(this);
    }
    
    confirmation(status,regUser){
        this.setState({registered: status, registeredUser:regUser, error: ''});
        
    }

    handleFName(e) {
        e.preventDefault();
        
        var user = this.state.user;
        
        user.fname = e.target.value;
        if (!user.fname.match(/^[a-zA-Z]+$/)) {
            this.setState({error : "First name is not valid"});
        }
        else {
            
            this.setState({ user: user, error :''});
        }
        
    }
    handleLName(e) {
        e.preventDefault();
        var user = this.state.user;
        
        user.lname = e.target.value;
        if (!user.lname.match(/^[a-zA-Z]+$/)) {
            this.setState({error : "Last name is not valid"});
        }
        else {
            
            this.setState({ user: user, error :''});
        }
    }
    handleEmail(e) {
        e.preventDefault();
        var user = this.state.user;        
        user.email = e.target.value;      
        
        this.setState({ user: user, error :''})
        
    } 
    handlePassword(e) {
        e.preventDefault();
        var user = this.state.user;
        
        user.password = e.target.value;
        if (!user.password.match(/^(?=.*[A-Z])(?=.*[!@#$&*%^()+-,.])(?=.*[0-9])(?=.*[a-z]).{9}$/)) {
            this.setState({error : "Password rules: Make sure your password has atleast ONE Uppercase letter, ONE lowercase letter, ONE special character and ONE number."});
        }
        else {
            
            this.setState({ user: user, error :''});
        }
    }


    async handleSubmit(e){        
        e.preventDefault();
        var user= this.state.user;
        tracker = "entered submit";
        if(user.fname !== '' && user.lname !== ''&& user.email !== ''&& user.password !== ''){
                        
            let lastAtPos = user.email.lastIndexOf("@");
            let lastDotPos = user.email.lastIndexOf(".");
        
            if (
                !(
                lastAtPos < lastDotPos &&
                lastAtPos > 0 &&
                user.email.indexOf("@@") === -1 &&
                lastDotPos > 2 &&
                user.email.length - lastDotPos > 2
                )
            ) {
                
                this.setState({error : "Email is not valid"});
                
            }
             
            else{
                await api.post('createaccount', user)
                            .then((resp)=>{
                                var msg = resp.data;
                                console.log("Respose: "+msg);
                                if(msg ==="success"){
                                    this.confirmation(true, user);
                                    setTimeout(() => { this.reset() }, 3000)
                                }
                                else{
                                    if(msg !== '')
                                        this.setState({error:msg})
                                }
                            })
                            .catch((err)=>console.log(err))
                
            }
        }      
        else{ 
            this.setState({error: 'Some fields are missing or incorrect!'})
        }
        
    }

    reset(){
        this.setState({
            user:{
            fname: '',
            lname: '',
            email: '',
            password: ''},
            registered: false,
            error: ''
        })
        this.props.closeRegister(this.state.registeredUser);
    }

    cancelRegistration(){
        this.props.closeRegister({});
    
    }
    render(){
        return(
            <>
            {console.log(tracker)}
            <form className="signup-form" >
                <button className="button-close" onClick={this.cancelRegistration}><h5>X</h5></button>
                <fieldset>
                    <br/><label><h3>Sign up Form</h3></label><br/>
                    {console.log(this.state.user)}
                    <label htmlFor="fname"><h4>First Name <span className="required">*</span>:</h4></label><br/><input type={"text"} className="signup-fields" id="fname" value={this.state.user.fname} onChange={(e)=>this.handleFName(e)} required={true}></input><br/>
                    <label htmlFor="lname"><h4>Last Name <span className="required">*</span>:</h4></label><br/><input type={"text"} className="signup-fields" id="lname" value={this.state.user.lname} onChange={(e)=>this.handleLName(e)} required={true}></input><br/>
                    <label htmlFor="email"><h4>Email <span className="required">*</span>:</h4></label><br/><input type={"email"} className="signup-fields" id="email" value={this.state.user.email} onChange={(e)=>this.handleEmail(e)} required={true}></input><br/>
                    <label htmlFor="password"><h4>Password <span className="required">*</span>:</h4></label><br/><input type={"password"} className="signup-fields" id="password" value={this.state.user.password} onChange={(e)=>this.handlePassword(e)} required={true}></input><br/><br/>
                    <button onClick={(e)=>{this.handleSubmit(e)}}><h5>Submit</h5></button>
                </fieldset>
                {(this.state.registered) && <p>Thank you for registering with <em>duarequest.org</em></p>}
                <p>{this.state.error}</p>
            </form>
            </>
        );
    }
}