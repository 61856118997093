import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Request from './components/Prayer/RequestPrayer';
import Prayer from './components/Prayer/prayer.js';
import Home from './components/Home.js';
import CompletedDuas from './components/Prayer/CompletedDuas.js'
import DuaDescriptions from './components/Prayer/DuaDescriptions.js'
import Logo from './Logo.png'
import './App.css';
import Footer from "./components/Footer";
import Private from "./components/Private";
import ReactGA from 'react-ga4';
// import CreateAccount from "./components/Prayer/CreateAccount";
import Login from "./components/Prayer/Login";


const TRACKING_ID = "G-E02RZM8ZCQ"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      isLoggedIn: false
    }
    this.changeLoggedIn = this.changeLoggedIn.bind(this);
  }
  changeLoggedIn(){
    var isLoggedIn = !this.state.isLoggedIn;
    this.setState({isLoggedIn: isLoggedIn});
  }

  componentWillUnmount(){
    // localStorage.clear();
    sessionStorage.clear();
  }

  render(){
   
  // const prayerData = [{"prayerId":0,"prayerName":"YaSalamo","prayerImage":"/images/YaSalamo.png","prayerFor":"Fatima","prayerStatus":"Completed","active":false,"totalCount":1000,"tally":1000},{"prayerId":1,"prayerName":"Aayat-e-Karima","prayerImage":"/images/Aayat-e-Karima.png","prayerFor":"Muhammad","prayerStatus":"On going","active":true,"totalCount":120,"tally":12},{"prayerId":2,"prayerName":"Aayat-e-Karima","prayerImage":"/images/Aayat-e-Karima.png","prayerFor":"Hasan","prayerStatus":"On going","active":true,"totalCount":1234,"tally":130},{"prayerId":3,"prayerName":"YaSalamo","prayerImage":"/images/YaSalamo.png","prayerFor":"Ahsan","prayerStatus":"On going","active":true,"totalCount":5000,"tally":300},{"prayerId":4,"prayerName":"Aayat-e-Karima","prayerImage":"/images/Aayat-e-Karima.png","prayerFor":"Ahmed","prayerStatus":"On going","active":true,"totalCount":120000,"tally":0},{"prayerId":5,"prayerName":"AmmaiYujeebu","prayerImage":"/images/AmmaiYujeebu.png","prayerFor":"Fatima","prayerStatus":"On going","active":true,"totalCount":120000,"tally":0},{"prayerId":6,"prayerName":"Aayat-e-Karima","prayerImage":"/images/Aayat-e-Karima.png","prayerFor":"Hina","prayerStatus":"On going","active":true,"totalCount":1500,"tally":0}]


  return (
    //         CHANGE
    <div className="App">
      <figure className="Logo">
        <img src={Logo} alt={Logo} />
      </figure>
      <h1 style={{color:"#008080"}}>Dua Request</h1>
      <Router>
        <div className="navigation">
          {/* duarequest/ */}
          <ul className="linkRoutes">
            <li >
              <Link to="/" className="nav-link"><h5>Home</h5></Link>
            </li>
            <li >
              <Link to="/prayer" className="nav-link"><h5>Join a Dua</h5></Link>
            </li>
            <li >
              <Link to="/RequestPrayer" className="nav-link"><h5>Request a Dua</h5></Link>
            </li>
            <li >
              <Link to="/Completed" className="nav-link"><h5>Completed Duas</h5></Link>
            </li>
            <li >
              <Link to="/Descriptions" className="nav-link"><h5>Dua Descriptions</h5></Link>
            </li>
          </ul>


          <Switch>
            <Route exact path="/">              
                <Home />

            </Route>
            <Route path="/prayer">
                <Prayer /> 
              
            </Route>
            <Route path="/RequestPrayer">              
                <Request /> 
              
            </Route>
            <Route exact path="/Completed">              
                <CompletedDuas />

            </Route>
            <Route exact path="/Descriptions">              
                <DuaDescriptions />

            </Route>
            <Route exact path="/private/:code" children={<CallPrivate/>} />              
            
            
          </Switch>
        </div>
        <div>
          {/* {(!this.state.isLoggedIn) && <Login changeLoggedIn={this.changeLoggedIn}/>} */}
        </div>
      </Router>

      <Footer />
    </div>
  
  );
  
  }
}

function CallPrivate(){
  let {code} = useParams();
  return(
    <Private code = {code}/>
  )
}
export default App;



// REACT_APP_SERVER="http://localhost:5000/"