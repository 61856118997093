import { Component } from "react";
import ReactGA from 'react-ga4';


const axios = require('axios');

export default class JoinPrayer extends Component{
    constructor(props){
        super(props);
        this.state = {            
            prayer: props.prayer,
            contribution: 0,
            message: "",
            display: props.display
        }

       
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleOnContributionChange = this.handleOnContributionChange.bind(this);
    }

    

    reset(){
        this.setState({
            contribution: 0,
            message: "",
            display: false
        })

    }

    handleOnContributionChange=(e)=>{
        e.preventDefault();        
        this.setState({contribution: Math.abs(parseInt(e.target.value.replace(/\D/,'')))});
    }

    handleOnSubmit = (e) =>{
        e.preventDefault();
        let r = window.confirm("Are you sure you want to add "+this.state.contribution+" to the count.");
        if(r === true){
            var url = process.env.REACT_APP_SERVER +'update'; // CHANGE
            // var url = "http://localhost:5000/update";

        
            var prayer = this.state.prayer;
            var tally = prayer.tally+this.state.contribution;
            prayer.tally = tally;
            if(prayer.tally >= prayer.totalCount){
                prayer.active = false;
                prayer.prayerStatus = "Completed";
            } else {
                prayer.active = true;
                prayer.prayerStatus = "On going";
            }
        

            axios
            .post(url, prayer)  
            .then((resp) => {
                this.setState({prayer: prayer, message:"JazakAllah for your contribution!"});    
                 
                return (<p>Completed Count Updated!</p>);
            })
            .catch(err => {
                console.error(err);
            });
                
            ReactGA.event({
                category: 'Join',
                action: 'Joined a Prayer'
                });
            
            this.reset();
        }
        else return null;
    }

    render(){
        if (this.state.display){
        return(
            <div className="JoinPrayer">
                <form>
                    <fieldset>
                        <label><h4> How many recitations have you completed?</h4></label><br/>
                        <label><p>Outstanding Count: {this.state.prayer.totalCount - this.state.prayer.tally}</p></label><br/>
                        <input type="number" value={this.state.contribution} onChange={this.handleOnContributionChange}/><br/>
                        <button onClick={this.handleOnSubmit}><p><strong>Submit</strong></p></button>
                        <p>{this.state.message}</p>
                    </fieldset>
                </form>
            </div>
        );
        }
        else return null;       
    }
}