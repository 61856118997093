import { Component } from "react";
import axios from 'axios'
import Loader from '../../loader.gif'

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
    // baseURL : 'http://localhost:5000'
  })

var prayerData = {dua_data:[],data:[]};


export default class DuaDescriptions extends Component{

    constructor(props){
        super(props);
        this.state = {
            isLoaded:false
        }
    }
    
    getDuaData(){  
        if(this.state.isLoaded === false){
            api.get('/')
                .then((response)=>{prayerData = response.data; this.setState({isLoaded:true});})
                .catch((err)=>{console.log(err)})
        }
    }
    render(){
        this.getDuaData();

        if(prayerData.dua_data.length>0){
            var duaData = prayerData.dua_data;
            const displayDesc = duaData.map((data,index)=>{
                return(
                    <div key={index} className="eachDua">
                        <h4>{data.prayerName}</h4>
                        <p>{data.prayerDescription}</p>
                    </div>
                )
            })
        
            return(            
                <div className="duaDescriptions">
                    {(this.state.isLoaded) ? displayDesc : <img src={Loader} alt="loading..." className="loader"/> }
                </div>
            );
        }
        return null
    }
}