import React, { Component } from "react";
import JoinPrayer from "./JoinPrayer";
import "../../App.css"
import Messages from "./Messages.js";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
       
})

export default class Dua extends Component {

    constructor(props) {

        super(props);
        this.greRef = React.createRef();
        this.state = {
            prayer: props.prayer,
            dua: props.dua,
            joinPrayer: props.join,
            buttonText: "Add to Count",
            fontColor: "#fefefa",
            bgColor: "#339999",
            borderColor: "1px solid #B5EAD7",
            userName: '',
            showMessageBox: false,
            token : '',
            error: ''
        }
        this.handleOnButtonClick = this.handleOnButtonClick.bind(this);
        this.saveUserName = this.saveUserName.bind(this);
        this.leaveAMessage = this.leaveAMessage.bind(this);

    }

    // This is the submit handler
    // check reCAPTCHA verification here
    async leaveAMessage(e) {
        e.preventDefault();
        var token = {token: await this.greRef.current.executeAsync()};
        api.post('grecaptcha', token)
            .then((resp) => {
                if(resp.status !== 200) { this.setState({ error: 'Bots are not allowed!'}) };
                if(this.state.userName === '') { this.setState({ error:'Kindly provide a name to view/post comments.' }) };
                (this.state.userName !== '' && resp.status === 200) ? this.setState({ showMessageBox: true }) : this.setState({ showMessageBox: false });
             })
            .catch(err => {
            console.error(err);
            });
        
        
        this.greRef.current.reset();
    }

    saveUserName(e) {
        e.preventDefault();
        var name = e.target.value;
        if (!name.match(/^[a-zA-Z]+$/)) { e.target.value = ''; }
        else { this.setState({ userName: name, error: '' }); }
    }

    // Add to Count/Done Button Handler
    handleOnButtonClick(e) {
        e.preventDefault();
        if (this.state.buttonText === "Add to Count") {
            this.setState({ joinPrayer: true, buttonText: "Done", fontColor: "#339999", bgColor: "#fefefa" })

        }
        else {
            this.setState({ joinPrayer: false, buttonText: "Add to Count", fontColor: "#fefefa", bgColor: "#339999" })
        }
    }

    componentWillUnmount() {
        this.setState({
            prayer: []
        })
    }

    render() {

        return (
            <>
                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} size="invisible" ref={this.greRef} />
                <div className="Dua">

                    {/* Display Complete Info */}
                    <h4>Dua For: <span className="prayerfor">{this.state.prayer.prayerFor}</span></h4><br />
                    <p>Dua Name: <span id="prayername">{this.state.prayer.prayerName}</span></p><br />
                    {(this.state.prayer.requestedBy !== "") && <p>Requested By: {this.state.prayer.requestedBy}</p>}<br />
                    <img src={this.state.dua.prayerImage} alt={this.state.dua.prayerImage} />
                    <p>{this.state.dua.transliteration}</p>
                    <p>Niyyah: {this.state.prayer.niyyah}</p>
                    {/* <p>Dua Status: <strong>{this.state.prayer.prayerStatus}</strong></p> */}
                    <p>Amount to be Recited: {this.state.prayer.totalCount}</p>
                    <p>Total Completed Count: {this.state.prayer.tally}</p>
                    <br />

                    {/* Join Prayer Button */}
                    {this.state.prayer.active && <button style={{ color: this.state.fontColor, backgroundColor: this.state.bgColor, border: this.state.borderColor }} onClick={this.handleOnButtonClick}><p><strong>{this.state.buttonText}</strong></p></button>}
                    <br />

                    {/* Display Add to Prayers count widget if user clicks join button */}
                    <div>{this.state.joinPrayer && <JoinPrayer prayer={this.state.prayer} display={true} />} </div>
                    <br />

                    {/* <Login /> */}

                    {/* Message Display Component */}
                    <div id="message-box" className="message-box">
                        <label htmlFor="name">Enter name to view or post comments  </label> <br />
                        <input type={"text"} maxLength={100} value={this.state.userName} onChange={this.saveUserName} />

                        <button onClick={this.leaveAMessage}> 
                            Show Comments
                        </button>
                        <br/>{this.state.error}<br/>
                        {(this.state.showMessageBox) && <Messages name={this.state.userName} prayer={this.state.prayer}/>}

                    </div>
                </div>

            </>
        );
    }
}