import { Component } from "react";
import Join from "../Prayer/JoinPrayer";
import DuaSnapShot from "./DuaSnapshot";
import axios from 'axios'
import Loader from '../../loader.gif'
import Dua from "./Dua.js"
// import Search from "./Search";

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
    // baseURL: "http://localhost:5000"                        //change     
})


// var joinPrayer = false;

//changed prayerData = [] to prayerData = {dua_data:[], data:[]}
var prayerData = { dua_data: [], data: [] };
var allPrayers = null;

export default class Prayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataLoaded: false,
            joinPrayer: false,
            searchText: '',
            searchError: '',
            searchStart: false,
            tempData: [],
            prayerToJoin: {},
            showDetails: false,
            prayer: [],
            dua: {}
        }
        this.handleOnButtonClick = this.handleOnButtonClick.bind(this);
        this.ShowJoinButton = this.ShowJoinButton.bind(this);
        this.getData = this.getData.bind(this);
        this.getDua = this.getDua.bind(this);
        this.showPrayerDetails = this.showPrayerDetails.bind(this);
    }

    showPrayerDetails(prayerToShow, duaToShow){
        var showPrayer = new Array(prayerToShow);
        // console.log(showPrayer);
        this.setState({
            prayer: showPrayer,
            showDetails: true,
            dua: duaToShow
        });
        // prayerData.data = prayerToShow;  
    }

    getData() {
        // the dataLoaded is used to re-render prayer component after JSON data is loaded and ready to display
        if (this.state.dataLoaded === false) {
            
            api
                .get('/')
                .then((res) => { prayerData = res.data; this.setState({ tempData:prayerData.data, dataLoaded: true }); })
                .catch(err => {
                    console.error(err);
                });
            
        }
    }

    handleOnButtonClick(datas) {

        this.setState({ joinPrayer: true, prayerToJoin: datas });

    }

    ShowJoinButton(props) {
        if (props.join === false) {
            return null;
        }
        else {
            return (
                <button onClick={this.handleOnButtonClick}>Join</button>
            );
        }
    }

    getDua(props) {
        for (var i = 0; i < prayerData.dua_data.length; i++) {
            if (prayerData.dua_data[i].prayerName === props.prayerName) { return prayerData.dua_data[i]; }
        }
    }
    handleSearch(e){
        e.preventDefault();
        var text = e.target.value.replace(/[$&+,:;=?[\]@#|{}'<>^*()%!/]/,"");               
        this.setState({searchText: text});
    }
    onSearchStart(e){
        try{
        // e.preventDefault();
        var searchText = this.state.searchText;
        prayerData.data = this.state.tempData;
        if (!searchText.match(/^[a-zA-Z0-9,\-_\\\s]+$/)) {
            this.setState({searchError:'Nothing to search', searchStart:false, searchText: ''})
        }
        else if(searchText === ''){
            prayerData.data = this.state.tempData
        }
        else{            
            this.setState({searchStart:true, searchError: ''});
            var searchtext = this.state.searchText.toUpperCase();
            var data = [];
            for(var i=0; i<prayerData.data.length; i++){
                var prayerfor = prayerData.data[i].prayerFor.toUpperCase();
                
                if (prayerfor.indexOf(searchtext) > -1){
                    data.push(prayerData.data[i])
                }
            }
            prayerData.data = data;
        }
        
        }

        catch(exception){
            console.log(exception)
        }
    }
    handleClearButtonClick(){
        this.setState({searchText:"",searchStart:false})
        prayerData.data = this.state.tempData;
    }
    
    render() {
        //1. retrieve data from the backend
        this.getData();

        //2. display all the prayers to the screen

        allPrayers = (prayerData.data.length > 0) ? prayerData.data.map((datas, index) => {
            return (
                
                (datas.active) && <div key={index}><DuaSnapShot prayer={datas} join={false} dua={this.getDua(datas)} showPrayerDetails={this.showPrayerDetails}/></div> 
                 
            );
        }) : <h5>"There are no Duas requested at the moment."</h5>;

        //3. Display Add to Prayers count widget if user clicks join button
        return (
            <>
                <div className="Prayer">
                    {(!this.state.showDetails) ? 
                        <div>
                        <div className="search">
                        <input type="text" className="searchTextbox" value={this.state.searchText} placeholder="Search by person name" onChange={this.handleSearch.bind(this)}/>
                        <button onClick={this.onSearchStart.bind(this)}>Search</button> <button onClick={this.handleClearButtonClick.bind(this)}>Clear</button>
                        </div>
                        {/* {this.state.searchStart && <div><Search words={this.state.searchText} prayerList={prayerData.data}/></div>} */}
                        <div>{this.state.searchError}</div>
                        <h2>Ongoing Duas</h2>
                        <h5>Dear reciters, JazakAllah for your support and contributions. A few tips to help you navigate through the app:<br />
                            <ul className="tips">
                                <li>The count of the duas is by single recitation of a dua. In case of counting by tasbeeh please update your contribution as number of tasbeeh recited x 100.</li>
                                <li> Once you confirm your contribution it will be updated in the database. Clicking "Done" will update the Total Completed Count showing on your page. If you do not click Done, your contribution will still count. Simply refreshing the page will show you the updated Total Completed Count too.</li>
                                </ul>
                        </h5> <br /><br/>
                        <div className="dua-container">
                        {this.state.joinPrayer ? <Join display={this.state.joinPrayer} prayer={this.state.prayerToJoin} />
                            : (this.state.dataLoaded ? allPrayers : <img src={Loader} alt="loading..." className="loader" />)} </div>
                        </div> : 
                        <div>
                            <Dua prayer={this.state.prayer[0]} join={false} dua={this.state.dua} />
                            <button onClick={()=>{this.setState({showDetails: false})}}>Back</button>
                        </div>}
                </div> 
            </>
        ); 

    }
}