
import { Component } from "react";
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactGA from 'react-ga4';

import CreateAccount from "./CreateAccount.js";

const axios = require('axios');
const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER       
})

var duacode = '';
var duas=[];

function StartPrayer(props) {
    var prayer = props;
        
    console.log(prayer);
    api
        .post('',prayer)
        .then((resp) => { console.log(resp); if (resp.data !== 'success') duacode = resp.data })
        .catch(err => {
            console.error(err);
        });
    
    ReactGA.event({
        category: 'User',
        action: 'Started a Prayer'
        });
}

export default class RequestPrayer extends Component {
    constructor(props) {
        super(props);
        this.handleStartPrayer = this.handleStartPrayer.bind(this);
        this.closeRegister = this.closeRegister.bind(this);
        this.state = {
            user:{fname: "Hina", lname:"Raza"},
            userSet: true,
            duasloaded: false,
            prayer: {
                prayerId: 0,
                prayerName: '',
                niyyah: '',
                prayerFor: '',
                requestedBy: '',
                audience: 'public',
                prayerStatus: '',
                active: false,
                totalCount: 0,
                tally: 0,
                email: ''
            },
            warning: {
                message: '',
                status: false
            },
            signup: false,
            nameError: '',
            niyahError: '',
            err: false,
            copied: false,
        }
        this.populateDuas = this.populateDuas.bind(this);
    }
    handlePrayerNameChange(e) {
        var prayer = this.state.prayer;
        prayer.prayerName = e.target.value;
        this.setState({ prayer: prayer });
    }
    handlePrayerForChange(e) {
        var prayer = this.state.prayer;
        var error = '';
        prayer.prayerFor = e.target.value;
        if (!prayer.prayerFor.match(/^[a-zA-Z\s]+$/)) {
            error = "The name you entered has some invalid characters.";
            this.setState({ nameError: error, err: true });
        }
        else {
            error = "";
            this.setState({ prayer: prayer, nameError: error, err: false });
        }
    }
    handleRequestedByChange(e){
        var prayer = this.state.prayer;
        var error = '';
        prayer.requestedBy = e.target.value;
        if (!prayer.requestedBy.match(/^[a-zA-Z\s]+$/)) {
            error = "The name you entered has some invalid characters.";
            this.setState({ nameError: error, err: true });
        }
        else {
            error = "";
            this.setState({ prayer: prayer, nameError: error, err: false });
        }
    }
    handleNiyyahChange(e) {
        var prayer = this.state.prayer;
        var error = '';
        prayer.niyyah = e.target.value;
        if (!prayer.niyyah.match(/^[a-zA-Z0-9!.,\-_\\\s]+$/)) {
            error = "The message you entered has some invalid characters."
            this.setState({ niyahError: error, err: true });
        }
        else {
            error = "";
            this.setState({ prayer: prayer, niyahError: error, err: false });
        }
    }
    handleCountChange(e) {
        var prayer = this.state.prayer;
        prayer.totalCount = parseInt(e.target.value);
        this.setState({ prayer: prayer });
    }
    handleEmail(e) {
        var prayer = this.state.prayer;
        prayer.email = e.target.value;
        this.setState({ prayer: prayer });
    }
    handleRadioChange(value) {
        var prayer = this.state.prayer;
        prayer.audience = value;
        this.setState({ prayer: prayer });
        
    }
    reset() {
        this.setState({
            user:{fname: "", lname:""},
            userSet: true,
            prayer: {
                prayerId: 0,
                prayerName: '',
                prayerFor: '',
                requestedBy: '',
                niyyah: '',
                audience: 'public',
                prayerStatus: '',
                active: false,
                totalCount: 0,
                tally: 0,
                email: ''
            },
            warning: {
                message: '',
                status: false
            },
            nameError: '',
            niyahError: '',
            err: false
        })
    }

    handleStartPrayer(e) {
        e.preventDefault();
        var prayer = this.state.prayer;
        var warning = this.state.warning;
        var err = this.state.err;
        warning.status = true;
        if (prayer.prayerName === '' || prayer.prayerFor === '' || prayer.niyyah === '' || prayer.totalCount <= 0 || prayer.audience === '' || err === true) {
            warning.message = 'Some fields are missing or invalid. Kindly wait for form to clear then refill the form.'
            this.setState({ warning: warning });
        }

        else {
            prayer.prayerStatus = 'On going';
            prayer.active = true;
            warning.message = "Your request for dua has been added";
            this.setState({ prayer: prayer, warning: warning });
            StartPrayer(prayer); //this is for database connection
        }

        setTimeout(() => { this.reset() }, 3000)
    }

    async populateDuas(){
        await api.get('/dua')
        .then((resp)=>{
            duas = resp.data; this.setState({duasloaded:true});
        })
        .catch((err)=>{console.log(err)})
        
    }

    closeRegister(user){
        this.setState({signup:false})
        if(user.fname !== ""){this.setState({user:user, userSet:true})}
    }
    render() {
        console.log(this.state.duasloaded);
        if (this.state.duasloaded === false) { this.populateDuas()}
        var displayduas = (duas.length > 0) ? duas.map((dua,index)=>{
            
            return(
                <option key={index} value={dua.prayerName}>{dua.prayerName}</option>
            )    
        }): null;
        return (
            <>
            <div className="RequestPage">
                {/* <div className="user-info">{(this.state.userSet) && <h5>{this.state.user.fname}&nbsp;{this.state.user.lname}</h5>}</div>
                <div> 
                    <p>Register with duarequest.org to moderate your dua requests.</p>
                    <button onClick={()=>{this.setState({signup:true})}}  >Register</button>
                </div>
                {(this.state.signup) && <CreateAccount closeRegister={this.closeRegister}/>} */}

                    <form>
                        <fieldset>
                            <h5>Kindly fill out all the fields below.</h5><br />
                            <label htmlFor="prayerName"><h3>Select Dua<span className="required">*</span>:</h3></label> &nbsp;&nbsp;
                            <select name="prayerName" id="prayerName" value={this.state.prayer.prayerName} className="dropdown" onChange={this.handlePrayerNameChange.bind(this)}>
                                <optgroup className="dropdown" >
                                    {(this.state.duasloaded) && displayduas}
                                    {/* <option value={this.state.prayer.prayerName} >{this.state.prayer.prayerName}</option>
                                    <option value="Aayat-e-Karima">Aayat-e-Karima</option>
                                    <option value="YaSalamo">Ya Salamo</option>
                                    <option value="AmmaiYujeebu">Ammai Yujeebo</option>
                                    <option value="YaNaruKoonee">Ya Naru Koonee</option> */}
                                </optgroup>
                            </select>
                            <br /><br />
                            <label htmlFor="prayerFor"><h3>Dua For<span className="required">*</span>:</h3></label> &nbsp;&nbsp;
                            <input type="text" name="prayerFor" id="prayerFor" placeholder="Muhammad Ali" value={this.state.prayer.prayerFor} onChange={this.handlePrayerForChange.bind(this)} />
                            <br />
                            <label>{this.state.nameError}</label>

                            <br /><br />
                            <label htmlFor="requestedBy"><h3>Requested By:</h3></label> &nbsp;&nbsp;
                            <input type="text" name="requestedby" id="requestedby" placeholder="Muhammad Ali" value={this.state.prayer.requestedBy} onChange={this.handleRequestedByChange.bind(this)} />
                            <br />
                            <label>{this.state.nameError}</label>

                            <br /><br />
                            <label htmlFor="niyyah"><h3>Niyyah<span className="required">*</span>:</h3></label> &nbsp;&nbsp;
                            <textarea type="text" name="niyyah" id="niyyah" placeholder="Dua-e-sehat for quick recovery of ..." maxLength={350} value={this.state.prayer.niyyah} onChange={this.handleNiyyahChange.bind(this)} />
                            <br />
                            <label>{this.state.niyahError}</label>
                            <br /><br />
                            <label htmlFor="count"><h3>Total Count<span className="required">*</span>:</h3></label> &nbsp;&nbsp;
                            <input type="number" name="count" id="count" max="9999999999" size="10" value={this.state.prayer.totalCount} onChange={this.handleCountChange.bind(this)} />
                            <br /><br />


                            {/* <label htmlFor="email"><p>Please provide an email address if you would like to receive email notification when your requested dua is complete.</p></label>
                        <input type="email" name="email" id="email" placeholder="name@email.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={this.state.prayer.email} onChange={this.handleEmail.bind(this)} /> 
                        <br/><br/> */}


                            <div className="scope">
                                <p>Do you want to start a public or private prayer?</p>
                                <RadioGroup onChange={this.handleRadioChange.bind(this)} vertical="true" value={this.state.prayer.audience}>
                                    <RadioButton value="public" rootColor="#008080" pointColor="#B2D8D8">
                                        Public
                                    </RadioButton>
                                    <RadioButton value="private" rootColor="#008080" pointColor="#B2D8D8">
                                        Private
                                    </RadioButton>
                                </RadioGroup>
                            </div>
                            <button onClick={this.handleStartPrayer}><h4>Start Dua</h4></button>
                            <div className="message" style={{ display: this.state.warning.status ? 'block' : 'none' }}><p>{this.state.warning.message}</p></div>
                            
                            {/* displaying private link */}
                            <div> <br/>{(this.state.prayer.audience === 'private') ? <div>
                                    After clicking "Start Dua" please wait for your private link to appear. You can copy this link using the copy symbol after the link and send via email/whatsapp/sms to your contacts. The private dua can only be accessed through this link. </div> 
                                : null}<br/></div>
                                
                            {/* code for copying private link */}
                            {(duacode !== '') ? <div><input value={duacode} disabled="disabled" style={{maxWidth:'80%'}}></input>
                            <CopyToClipboard text={duacode} onCopy={() => this.setState({copied: true})}>
                                <button onClick={(e) => e.preventDefault()}><i className="far fa-copy"></i></button>
                            </CopyToClipboard></div> : null
                            }    
                        </fieldset>
                    </form>
                </div>
            </>
        );
    }
}