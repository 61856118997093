import { Component} from "react";
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER
       
})

export default class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            error: '',
            user: {},
            credentials: {
                uid: '',
                pswd: ''
            }
        }
        this.setUID = this.setUID.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.closeLogin = this.closeLogin.bind(this);
    }

    setUID(e){
        e.preventDefault();
        var userInfo = this.state.credentials;
        userInfo.uid = e.target.value;
        this.setState({credentials: userInfo});
    }
    setPassword(e){
        e.preventDefault();
        var userInfo = this.state.credentials;
        userInfo.pswd = e.target.value;
        this.setState({credentials: userInfo});
    }
    closeLogin(){
        var username = this.state.user.fname + ' ' + this.state.user.lname;
        // localStorage.setItem("userinsession",username);
        sessionStorage.setItem("userinsession",username);
        this.props.handleLogin();
    }
    handleLogin(e){
        e.preventDefault();
        var userInfo = this.state.credentials;
        if(userInfo.uid !== '' && userInfo.pswd !== ''){
                        
            let lastAtPos = userInfo.uid.lastIndexOf("@");
            let lastDotPos = userInfo.uid.lastIndexOf(".");
        
            if (
                !(
                lastAtPos < lastDotPos &&
                lastAtPos > 0 &&
                userInfo.uid.indexOf("@@") === -1 &&
                lastDotPos > 2 &&
                userInfo.uid.length - lastDotPos > 2
                )
            ) {
                
                this.setState({error : "Email is not valid"});
                
            }
             
            else{
                var credentials = this.state.credentials;
                api.post('/getUser',credentials)
                    .then((resp) => {
                        var user = resp.data;
                        console.log(user);
                        if(user !== 'fail'){this.setState({user: user});this.closeLogin();}
                        else {this.setState({error: 'Incorrect email or password.'})}
                    })
                    .catch((error)=>console.log(error))
            }
        }
    }

    render(){
        return(
            <div className="login-form">
                <form>
                    <fieldset>
                        
                        <label htmlFor="uid"><h5>Email</h5></label><br/>
                        <input type={"email"} id="email" name="email" value={this.state.credentials.uid} onChange={this.setUID} placeholder="xyz@email.com"/><br/><br/>
                        <label htmlFor="password"><h5>Password</h5></label><br/>
                        <input type={"password"} id="password" name="password" value={this.state.credentials.pswd} onChange={this.setPassword} placeholder="password"/>
                        <br/>
                        <button onClick={this.handleLogin}>SignIn</button>
                        <h6>{this.state.error}</h6>
                    </fieldset>
                </form>
            </div>
        )
    }
}