import { Component } from "react";

export default class Footer extends Component{
    render(){
        return(
            <>
            <div className="Footer">
                <h5> For questions and feedback please send us an email on</h5>
                <h5><a href='mailto:duarequest110@gmail.com'>duarequest110@gmail.com</a></h5>
            </div>
            <div className="disclaimer">
                <h6><i className ="fas fa-exclamation"></i> This app is in the testing phase right now. We appologise for any disruptions to service. <i className="fas fa-exclamation"></i></h6>
                <h6>Attribution: search icons PNG Designed By 588ku from <a href="https://pngtree.com"> Pngtree.com</a></h6>
            </div>
            </>
        );
    }
}